import { LOCALE_CODE } from 'types/enum'

type symbolId = number


export type StockNewsFeedProps = {
  width?: number
  height?: number
  handleWidgetThrowError?: (error: Error) => void
  lang?: LOCALE_CODE
  redirectCallback?: (data: any) => void
  isAdaptive: boolean
  showBrand?: boolean
  companySymbolId: symbolId
  isShowBeneathLink: boolean
}

export enum RoleType {
  Participant = "Participant",
  Target = "Target",
}

export enum Sentiment {
  Negative = "negative",
  Neutral = "neutral",
  Positive = "positive",
}


export type StockNewsFeedData = {
  headline: string;
  situation: string;
  announcedDate: number;
  mostImportantDate: number;
  roleType: RoleType;
  eventType: string;
  sentiment: Sentiment;
}
