export enum STATEMENT_TYPE {
  INCOME_STATEMENT = 'incomeStatement',
  BALANCE_SHEET = 'balanceSheet',
  CASH_FLOW = 'cashFlow',
}

export enum NUMBER_UNIT {
  TRILLION = 'T',
  BILLION = 'B',
  MILLION = 'M',
  NORMAL = '',
}

export enum BALENCE_SHEET_ACCOUNTING_KEY {
  ASSETS = 'assets',
  LIABILITIES = 'liabilities',
  EQUITY = 'equity',
}

export enum INCOME_STATEMENT_ACCOUNTING_KEY {
  TOTAL_REVENUES = 'totalRevenues',
  EBITDA = 'ebitda',
  RETURN_ON_EQUITY = 'returnOnEquity',
}

export enum CASH_FLOW_ACCOUNTING_KEY {
  NET_CASH_FLOW = 'netCashFlow',
  CAPITAL_EXPENDITURE = 'capitalExpenditure',
  ASSET_TURNOVER = 'assetTurnover',
}
