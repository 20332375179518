import styled, { css } from 'styled-components'
import { DimensionProps } from './types'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 424

export const Box = styled.div<DimensionProps>`
  width: ${({ isAdaptive, width }) => (isAdaptive ? '100%' : `${width}px`)};
  height: ${({ isAdaptive, height }) => (isAdaptive ? '100%' : `${height}px`)};
  display: flex;
  flex-direction: column;
`

export const WidgetBox = styled.div<{ disabledACYLink?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${mutualPaddingAndBorderRadiusMixing}
`

export const RedirectBox = styled.div<{ cursorPointer: boolean }>`
  cursor: ${({ cursorPointer = false }) => cursorPointer && 'pointer'};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h5<{ hasHover: boolean }>`
  text-align: left;
  ${({ theme }) => theme.typography.H5};
  font-style: normal;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.N75};
  margin-bottom: ${({ theme }) => theme.spacing.s4};

  ${({ theme, hasHover }) =>
    hasHover &&
    css`
      &:hover {
        color: ${theme.palette.F400};
      }
    `};
`

const RELEASE_DETAIL_TEXT_LINE_HEIGHT = 16

export const ReleaseDetailWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: ${RELEASE_DETAIL_TEXT_LINE_HEIGHT}px;
  color: ${({ theme }) => theme.palette.N300};
  display: flex;
  justify-content: space-between;
  // NOTE: To truncate words in multiple lines
  min-width: 0;

  .wholeSummaryTooltip {
    width: 95%;
    white-space: normal;
    max-width: 95%;
    z-index: 3;
    transform: ${({ theme }) =>
      theme.isRTL
        ? 'translate3d(50%, -90%, 1px);'
        : 'translate3d(-50%, -90%, 1px);'};
  }

  .truncatedSummary {
    display: inline;
    height: ${RELEASE_DETAIL_TEXT_LINE_HEIGHT * 2}px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    //NOTE: Below lines CSS are for truncating words in multiple lines in a flex child
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  > .infoIconWrapper {
    width: 16px;
    display: flex;
    flex-direction: column-reverse;
  }
`
