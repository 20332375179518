import { makeFunctionSerializeAndDeserializeAble } from './_iframeModeRelated/utils'
import { WidgetIframe, WidgetIframeConfig } from './_iframeModeRelated/types'
import { createIframe, setupIframeContentLoadedMessageListener } from './_iframeModeRelated/logics'
import './styles/WidgetGlobalStyleWrapper/index.css'

makeFunctionSerializeAndDeserializeAble()

const IFRAME_APP_URL = process.env.WIDGET_FINLOGIX_DOMAIN as string

const Widget: WidgetIframe = {
  config: {},
  iframe: null,

  init(config: WidgetIframeConfig) {
    this.config = config
    setupIframeContentLoadedMessageListener(this)
    createIframe(this, IFRAME_APP_URL)
  },

  update(newConfig: WidgetIframeConfig) {
    if (this.iframe?.contentWindow) {
      this.iframe.contentWindow.postMessage(JSON.stringify({ ...this.config, ...newConfig }), '*')
    }
  }
}

class WidgetIframeClass {
  config: WidgetIframeConfig
  iframe: HTMLIFrameElement | null
  isInitialized: boolean

  constructor() {
    this.config = {}
    this.iframe = null
    this.isInitialized = false
  }

  init(config: WidgetIframeConfig) {
    if (this.isInitialized) return
    this.config = config

    setupIframeContentLoadedMessageListener(this)
    createIframe(this, IFRAME_APP_URL)
    this.isInitialized = true
  }

  update(newConfig: WidgetIframeConfig) {
    if (this.iframe?.contentWindow) {
      this.iframe.contentWindow.postMessage(JSON.stringify({ ...this.config, ...newConfig }), '*')
    }
  }
}

if (process.env.SERVER_ENV !== 'production') {
  window.widgetModule = Widget
}

window.WidgetIframeClass = WidgetIframeClass

export default Widget

