import { getParser } from 'bowser'
import { defaultProps as stripBarDefaultProps } from 'features/StripBar/constant'
import { WidgetType } from 'types/enum'
import type { WidgetIframe, WidgetIframeConfig } from '../types'
import { WIDGET_MIN_HEIGHT as BIG_CHART_MIN_HEIGHT } from 'features/BigChart/container/styled'
import { WIDGET_MIN_HEIGHT as EARNING_CALENDAR_MIN_HEIGHT } from 'features/EarningCalendar/styled'
import { WIDGET_MIN_HEIGHT as SINGLE_SYMBOL_MIN_HEIGHT } from 'features/SingleSymbol/container/styled'
import { WIDGET_MIN_HEIGHT as SENTIMENT_MIN_HEIGHT } from 'features/Sentiment/styled'
import { WIDGET_MIN_HEIGHT as ECONOMIC_CALENDAR_MIN_HEIGHT } from 'features/EconomicCalendar/styled'
import { WIDGET_MIN_HEIGHT as SYMBOL_LIST_MIN_HEIGHT } from 'features/SymbolList/container/styled'
import { WIDGET_MIN_HEIGHT as SYMBOL_CHART_LIST_MIN_HEIGHT } from 'features/SymbolChartList/styled'
import { WIDGET_MIN_HEIGHT as MARKET_CHART_MIN_HEIGHT } from 'features/MarketChart/styled'
import { WIDGET_MIN_HEIGHT as FUNDAMENTAL_RATING_MIN_HEIGHT } from 'features/FundamentalRating/styled'
import { WIDGET_MIN_HEIGHT as FINANCIAL_STATEMENTS_MIN_HEIGHT } from 'features/FinancialStatementsChart/constants'
import { WIDGET_MIN_HEIGHT as NEWS_FEED_MIN_HEIGHT } from 'features/NewsFeed/styled'
import { WIDGET_MIN_HEIGHT as STOCK_NEWS_FEED_MIN_HEIGHT } from 'features/StockNewsFeed/styled'
import { WIDGET_MIN_HEIGHT as ACY_ADS_HORIZONTAL_MIN_HEIGHT } from 'featureMarketings/AcyAdsHorizontal/constant'
import { WIDGET_MIN_HEIGHT as SENTIMENT_VARIANT_MIN_HEIGHT, WIDGET_MIN_HEIGHT_WHEN_OPEN_BOTTOM_COLUMNS } from 'features/SentimentVariant/styled'
import { WIDGET_MIN_HEIGHT as FOREX_CALCULATORS_MIN_HEIGHT } from 'features/ForexCalculators/styled'
import { getURLWithoutQueryParameter } from '_iframeModeRelated/utils'

const setDefaultStyle = (iframe: HTMLIFrameElement, style?: string) => {
  const iframeStyle =
    `
    border: none;
    visibility: visible;
    background: none transparent;
    pointer-events: auto;
    touch-action: auto;
    z-index: 1;
  ` + style

  iframe.setAttribute('style', iframeStyle)
}

export const createIframe = (
  instance: WidgetIframe,
  iframeScriptURL: string,
) => {

  instance.iframe = document.createElement('iframe')
  instance.iframe.src = iframeScriptURL
  instance.iframe.referrerPolicy = 'origin'
  instance.iframe.setAttribute('scrolling', instance.config.type === WidgetType.CurrencyConverter ? 'no' : 'yes')
  // NOTE: Use loose sandbox mode to allow the widget to open links in a new tab and prevent iframe that open widget iframe overwrite it.
  instance.iframe.setAttribute('sandbox', 'allow-modals allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-downloads allow-presentation allow-popups-to-escape-sandbox')
  instance.config.page_url = getURLWithoutQueryParameter()
  instance.config.userAgent = window.navigator.userAgent
  instance.config.startTime = performance.now()
  instance.config.lang = instance.config.language
    ? instance.config.language
    : 'en'
  instance.config.theme = instance.config.theme
    ? instance.config.theme
    : 'light'

  setIframeStyle(instance)

  const domById = instance.config.renderDocumentId
    ? document.querySelector(`#${instance.config.renderDocumentId}`)
    : undefined

  if (domById) {
    domById.appendChild(instance.iframe)
    instance.config.isAdaptive &&
      domById.setAttribute('style', 'width: 100%; height: 100%')
  } else {
    const containers = document.querySelectorAll<HTMLElement>(
      '.finlogix-container',
    )
    const container = containers[containers.length - 1]
    container.appendChild(instance.iframe)
    instance.config.isAdaptive &&
      container.setAttribute('style', 'width: 100%; height: 100%')
  }
}

const setIframeStyle = (instance: WidgetIframe) => {
  const isAdaptive = instance.config.isAdaptive
  let iframeWidth: string
  let iframeHeight: string
  let iframeMinHeight: string

  switch (instance.config.type) {
    case WidgetType.StripBar:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive
        ? `${stripBarDefaultProps.height}px`
        : `${instance.config.height}px`
      break
    case WidgetType.SymbolInfo:
      iframeWidth = '100%'
      iframeHeight = '180px'
      break
    case WidgetType.BigChart:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${BIG_CHART_MIN_HEIGHT}px`
      break
    case WidgetType.SingleSymbol:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${SINGLE_SYMBOL_MIN_HEIGHT}px`
      break
    case WidgetType.Sentiment:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${SENTIMENT_MIN_HEIGHT}px`
      break
    case WidgetType.MarketChart:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${MARKET_CHART_MIN_HEIGHT}px`
      break
    case WidgetType.EconomicCalendar:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${ECONOMIC_CALENDAR_MIN_HEIGHT}px`
      break
    case WidgetType.SymbolList:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${SYMBOL_LIST_MIN_HEIGHT}px`
      break
    case WidgetType.SymbolChartList:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${SYMBOL_CHART_LIST_MIN_HEIGHT}px`
      break
    case WidgetType.EarningCalendar:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${EARNING_CALENDAR_MIN_HEIGHT}px`
      break
    case WidgetType.FinancialStatementsChart:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${FINANCIAL_STATEMENTS_MIN_HEIGHT}px`
      break
    case WidgetType.FundamentalRating:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${FUNDAMENTAL_RATING_MIN_HEIGHT}px`
      break
    case WidgetType.NewsFeed:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${NEWS_FEED_MIN_HEIGHT}px`
      break
    case WidgetType.StockNewsFeed:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${STOCK_NEWS_FEED_MIN_HEIGHT}px`
      break
    // NOTE: TradingCup widgets in iframe mode might not be in use anymore, remove the code when confirmed
    case WidgetType.TradingCupRanking:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive
        ? '100%'
        : (instance.config.height ? instance.config.height : 0) + 'px'
      break
    case WidgetType.TradingCupStatistics:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive
        ? '100%'
        : (instance.config.height ? instance.config.height : 0) + 'px'
      break
    case WidgetType.AcyAdsHorizontal:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${ACY_ADS_HORIZONTAL_MIN_HEIGHT}px`
      break
    case WidgetType.SentimentVariant:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = instance.config.isShowPriceRelatedColumn ? `${WIDGET_MIN_HEIGHT_WHEN_OPEN_BOTTOM_COLUMNS}px` : `${SENTIMENT_VARIANT_MIN_HEIGHT}px`
      break
    case WidgetType.MarginCalculator:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${FOREX_CALCULATORS_MIN_HEIGHT}px`
      break
    case WidgetType.CurrencyConverter:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${FOREX_CALCULATORS_MIN_HEIGHT}px`
      break
    case WidgetType.PositionSize:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = `${FOREX_CALCULATORS_MIN_HEIGHT}px`
      break
    default:
      iframeWidth = isAdaptive ? '100%' : `${instance.config.width}px`
      iframeHeight = isAdaptive ? '100%' : `${instance.config.height}px`
      iframeMinHeight = '300px'
  }

  instance.iframe &&
    setDefaultStyle(
      instance.iframe,
      `width: ${iframeWidth}; height: ${iframeHeight}; min-height: ${iframeMinHeight};`,
    )
}

export const setupIframeContentLoadedMessageListener = (instance: WidgetIframe) => {
  window.addEventListener('message', (e: MessageEvent) => {
    e.preventDefault()

    // NOTE: Some extensions e.g. react devtools will send message to parent window, ignore it
    if (typeof e.data !== 'string') {
      return
    }
      // Got know the iframe has been loaded, send configs to iframe, init the widget
      instance.iframe &&
        instance.iframe.contentWindow?.postMessage(
          JSON.stringify({ ...instance.config, iframeMode: true }),
          '*',
        )
  })
}

export const registryTracking = (config: WidgetIframeConfig, monitorURL: string) => {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      const {
        type,
        language,
        theme,
        page_url,
        startTime,
        width,
        height,
        widgetId,
        userAgent,
      } = config
      const duration = (performance.now() - (startTime || 0)) / 1000
      const size = `${width} x ${height}`
      const _theme = theme || 'light'
      const _language = language || 'en'
      const info = getParser(userAgent || '') as any
      const { browser, os, platform } = info.parsedResult
      const formData = new FormData()
      formData.append('widget_type', type || '')
      formData.append('language', _language)
      formData.append('theme', _theme)
      formData.append('page_url', page_url || '')
      formData.append('session_duration', duration + '')
      formData.append('size', size)
      formData.append('widget_id', widgetId || '')
      formData.append('browser', browser.name)
      formData.append('os', os.name)
      formData.append('device', platform.type)
      window.navigator.sendBeacon(monitorURL, formData)
    }
  })
}
