import styled, { css } from 'styled-components'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_WIDTH = 300
export const WIDGET_MIN_HEIGHT = 500

export const NewsItemListWrapper = styled.ul`
  list-style-type: none;
  padding-left: 0;
`

type BoxProps = {
  boxHeight: string
  boxWidth: string
}

export const Box = styled.div<BoxProps>`
    display: flex;
    flex-direction: column;
    width: ${({ boxWidth }) => boxWidth};
    height: ${({ boxHeight }) => boxHeight};
`

export const WidgetBox = styled.div<{ isShowBeneathLink: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${({ isShowBeneathLink }) => isShowBeneathLink ? 'calc(100% - 32px)' : '100%'};
    ${mutualPaddingAndBorderRadiusMixing}
`
