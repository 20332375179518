import styled from 'styled-components'
import { mutualPaddingAndBoxShowdow } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 172
export const WIDGET_MIN_HEIGHT_WHEN_OPEN_BOTTOM_COLUMNS = 240

export const Box = styled.div<{ width: string; height: string, isShowPriceRelatedColumn: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${({ isShowPriceRelatedColumn }) => isShowPriceRelatedColumn ? `${WIDGET_MIN_HEIGHT_WHEN_OPEN_BOTTOM_COLUMNS}px` : `${WIDGET_MIN_HEIGHT}px`};
`

export const WidgetBox = styled.div<{ withBorderBox: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme }) => theme.palette.N700};
  height: 100%;
  ${({ withBorderBox }) => withBorderBox && mutualPaddingAndBoxShowdow}
`

export const BrandIconWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`

export const WidgetTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  ${({ theme }) => theme.typography.H5}
  ${({ theme }) => theme.typography.Bold}
  color: ${({ theme }) => theme.palette.N75};
  padding-bottom: ${({ theme }) => theme.spacing.s2};
`

export const WidgetTitleSectionLeftPart = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.s2};
  align-items: center;
`

export const WidgetTitleSectionRightPart = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.s2};
  align-items: center;
`
