import palette from 'styles/palette'
import { CHART_SERIES_TYPE, LOCALE_CODE } from 'types/enum'
import { FinancialStatementsChartWidgetProps } from '../types'
import { STATEMENT_TYPE } from '../types/enum'

export const seriesColors = [
  palette.F400,
  palette.G400,
  palette.N75,
  palette.R400,
  palette.O400,
]

export const statements = Object.values(STATEMENT_TYPE)

export const GRIDS_COUNT = 3

export const WIDGET_MIN_HEIGHT = 424
export const WIDGET_DEFAULT_WIDTH = 580

export const defaultProps: Required<FinancialStatementsChartWidgetProps> = {
  width: WIDGET_DEFAULT_WIDTH,
  height: WIDGET_MIN_HEIGHT,
  isAdaptive: false,
  symbolId: 10007,
  defaultStatementType: STATEMENT_TYPE.INCOME_STATEMENT,
  chartType: CHART_SERIES_TYPE.BAR,
  lang: LOCALE_CODE.ENGLISH,
  showBrand: true,
  toChart: () => {
    return
  },
  isShowBeneathLink: false,
}
