import styled, { css } from 'styled-components'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 180

type OutterBoxProps = {
  width: string
  height: string
}

export const OutterBox = styled.div<OutterBoxProps>`
  ${({ width, height, }) => css`
    width: ${width};
    height: ${height};
    min-height: ${`${WIDGET_MIN_HEIGHT}px`};
    display: flex;
    flex-direction: column;
  `}
`

export const WidgetWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: ${theme.palette.N700};
    display: flex;
    flex-direction: column;
    ${mutualPaddingAndBorderRadiusMixing}
  `}
`

export const StyledButtonContainer = styled.div`
  width: calc(100% + 32px);
  margin-left: -16px;
  transform: translateY(16px);
`

export const BrandIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`
