import styled from 'styled-components'

export const WIDGET_MIN_HEIGHT = 280

type BoxProps = {
  width: string
  height: string
}

export const Box = styled.div<BoxProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${`${WIDGET_MIN_HEIGHT}px`};
  display: flex;
  flex-direction: column;
`

export const WidgetBox = styled.div`
  display: flex;
  flex-direction: column;
   width: 100%;
  height: 100%;
`
