// REF: https://saimana.com/list-of-country-locale-code/
export enum LOCALE_CODE {
  ENGLISH = 'en',
  ENGLISH_PHILIPPINES = 'tl', // NOTE: "tl" not found, should be "en_PH" instead
  CHINESE = 'zh',
  CHINESE_TAIWAN = 'zh_tw',
  INDONESIAN = 'id',
  VIETNAMESE = 'vi',
  ARABIC = 'ar',
  BULGARIAN = 'bg',
  CZECH = 'cs',
  DANISH = 'da',
  GERMAN = 'de',
  SPANISH = 'es',
  FINNISH = 'fi',
  FRENCH = 'fr',
  HINDI = 'hi',
  ITALIAN = 'it',
  JAPANESE = 'ja',
  KOREAN = 'ko',
  NORWEGIAN = 'no',
  POLISH = 'pl',
  PORTUGUESE = 'pt',
  ROMANIAN = 'ro',
  RUSSIAN = 'ru',
  THAI = 'th',
  MALAY = 'ms',
}

export enum OHLC_STATUS {
  UP = 'UP',
  DOWN = 'DOWN',
  NONE = 'NONE',
}

// NOTE: enum WidgetType value must not change or may effect iframe script usage
// <script type="text/javascript">
// 		Widget.init({
// 				type: "BigChart",
// 				...
// 		});
// </script>
export enum WidgetType {
  StripBar = 'StripBar',
  MarketChart = 'MarketChart',
  SingleSymbol = 'SingleSymbol',
  SymbolList = 'SymbolList',
  SymbolInfo = 'SymbolInfo',
  BigChart = 'BigChart',
  Specification = 'Specification',
  EconomicCalendar = 'EconomicCalendar',
  SmallChart = 'SmallChart',
  TradingCupRanking = 'TradingCupRanking',
  TradingCupStatistics = 'TradingCupStatistics',
  EarningPerformance = 'EarningPerformance',
  SymbolChartList = 'SymbolChartList',
  EarningCalendar = 'EarningCalendar',
  FinancialStatementsChart = 'FinancialStatementsChart',
  FundamentalRating = 'FundamentalRating',
  NewsFeed = 'NewsFeed',
  StockNewsFeed = 'StockNewsFeed',
  Sentiment = 'Sentiment',
  SentimentVariant = 'SentimentVariant',
  SentimentOverview = 'SentimentOverview',

  // ForexCalculators
  CurrencyConverter = 'CurrencyConverter',
  MarginCalculator = 'MarginCalculator',
  PositionSize = 'PositionSize',

  // Marketings
  AcyAds = 'AcyAds',
  AcyAds2 = 'AcyAds2',
  AcyAds3 = 'AcyAds3',
  AcyAdsHorizontal = 'AcyAdsHorizontal',

  // ChromeExtension
  FinlogixChromeExtension = 'FinlogixChromeExtension',
}

export enum SymbolCategory {
  'Forex' = 'Forex',
  'Indices' = 'Indices',
  'Commodities' = 'Commodities',
  'Cryptocurrency' = 'Cryptocurrency',
  'Stocks' = 'Stocks',
  'ETFs' = 'ETFs',
  'Futures' = 'Futures',
}

export enum Resolution {
  M1 = 'M1',
  M5 = 'M5',
  M15 = 'M15',
  M30 = 'M30',
  H1 = 'H1',
  H2 = 'H2',
  H4 = 'H4',
  D1 = 'D1',
  W1 = 'W1',
  MN = 'MN',
}

export enum ImpactOption {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum TimePeriodOption {
  RecentAndNext = 'recentAndNext',
  Yesterday = 'yesterday',
  ThisWeek = 'thisWeek',
  Today = 'today',
  Tomorrow = 'tomorrow',
  NextWeek = 'nextWeek',
  ThisMonth = 'thisMonth',
  NextMonth = 'nextMonth',
  LastWeek = 'lastWeek',
  LastMonth = 'lastMonth',
}

export enum Placeholder {
  NoResult = '--'
}

export enum CHART_SERIES_TYPE {
  LINE = 'line',
  BAR = 'bar',
}

// from @zerologix/data/store/api/userSymbol.js
export enum SymbolsType {
  COMMODITY = 'Commodities',
  CRYPTO = 'Crypto',
  FOREX = 'Forex',
  INDICE = 'Indices',
  FAVOURITE = 'Favourite',
  CRYPTOCURRENCY = 'Cryptocurrency',
  ALL = 'All',
  STOCKS = 'Stocks',
}
