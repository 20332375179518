const lightPalette = {
  // Finlogix
  F50: '#E5F2FA',
  F75: '#CCE6F6',
  F100: '#9ACDEC',
  F200: '#67B4E3',
  F300: '#359BD9',
  F400: '#0282D0',
  F500: '#0268A6',
  F600: '#014E7D',
  F700: '#013453',
  F800: '#001A2A',
  // Neutral
  N50: '#000000',
  N75: '#1C1C1C',
  N100: '#2A2A2A',
  N200: '#3E3E3E',
  N300: '#5C5C5C',
  N350: '#8C8C8C',
  N400: '#BBBBBB',
  N450: '#969696',
  N500: '#DDDDDD',
  N600: '#F4F4F4',
  N700: '#FFFFFF',
  // Extended
  G400: '#00BA34',
  R400: '#E92C2C',
  O400: '#F98600',
  // Others
  LP400: '#00B875',
  ZC400: '#9130DE',
  ACYBlue: '#013B81',
}

export const darkPalette: Palette = {
  // Finlogix
  F50: '#E5F2FA',
  F75: '#CCE6F6',
  F100: '#9ACDEC',
  F200: '#67B4E3',
  F300: '#359BD9',
  F400: '#0282D0',
  F500: '#0268A6',
  F600: '#014E7D',
  F700: '#013453',
  F800: '#001A2A',
  // Neutral  it reversed the Neutral one in lightPalette
  N50: '#FFFFFF',
  N75: '#F4F4F4',
  N100: '#DDDDDD',
  N200: '#969696',
  N300: '#BBBBBB',
  N350: '#8C8C8C',
  N400: '#5C5C5C',
  N450: '#3E3E3E',
  N500: '#2A2A2A',
  N600: '#5C5C5C',
  N700: '#1C1C1C',
  // Extended
  G400: '#00BA34',
  R400: '#E92C2C',
  O400: '#F98600',
  // Others
  LP400: '#00B875',
  ZC400: '#9130DE',
  ACYBlue: '#013B81',
}

export type Palette = typeof lightPalette
export default lightPalette
