import { LOCALE_CODE } from 'types/enum'
import type { WidgetTheme } from 'styles/theme'
import { AcyAdsAvaliableLanguages } from 'featureMarketings/types'

export const WIDGET_MIN_HEIGHT = 90

export type TrackingIdentifier = 'fxnewstoday' | 'liquidityfinder' | 'financemagnates'

export type AcyAdsHorizontalWidgetProps = {
  lang: AcyAdsAvaliableLanguages
  isAdaptive: boolean
  width?: number
  height?: number
  identifier: TrackingIdentifier
} & WidgetTheme

export const defaultProps: AcyAdsHorizontalWidgetProps = {
  width: 728,
  height: 90,
  isAdaptive: false,
  lang: LOCALE_CODE.ENGLISH,
  identifier: 'liquidityfinder',
  theme: 'light',
  withBorderBox: false,
}
