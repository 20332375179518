import { css } from 'styled-components'

export const mutualPaddingAndBorderRadiusMixing = css`
    padding: ${({ theme }) => theme.spacing.s3};
    border: ${({ theme }) => theme.withBorderBox ? `1px solid ${theme.palette.N500}` : 'none'};
    border-radius: 4px;
  `

export const mutualPaddingAndBoxShowdow = css`
    padding: ${({ theme }) => theme.spacing.s3};
    background: ${({ theme }) => theme.palette.N700};
    box-shadow: 0px 0px 4px 0px rgba(120, 120, 120, 0.25);
    border-radius: 8px;
    // NOTE: This is a hack to fix the issue of the right boxshadow border not showing up
    width: calc(100% - 1px);
    height: calc(100% - 1px);
  `

export const innerMutualPaddingAndBorderRadiusMixing = css`
    border: ${({ theme }) => theme.withBorderBox ? `1px solid ${theme.palette.N500}` : 'none'};
    border-radius: 4px;
  `
