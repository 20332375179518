import styled from 'styled-components'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 500

export const Box = styled.div<{ width: string; height: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${`${WIDGET_MIN_HEIGHT}px`};
`

export const WidgetBox = styled.div<{ isShowBeneathLink: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme }) => theme.palette.N700};
   height: ${({ isShowBeneathLink }) => isShowBeneathLink ? 'calc(100% - 32px)' : '100%'};
  ${mutualPaddingAndBorderRadiusMixing}
`