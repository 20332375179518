import styled, { css } from 'styled-components'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 320

type EarningCalendarWidgetWrapperProps = {
  width: number
  height: number
  isAdaptive: boolean
}

export const Box = styled.div<EarningCalendarWidgetWrapperProps>`
  ${({ width, height, isAdaptive, }) => css`
    width: ${isAdaptive ? '100%' : `${width}px`};
    height: ${isAdaptive ? '100%' : `${height}px`};
    min-height: ${`${WIDGET_MIN_HEIGHT}px`};
    display: flex;
    flex-direction: column;
    position: relative;
  `}
`

export const WidgetBox = styled.div`
${({ theme }) => css`
   width: 100%;
   height: 100%;
   flex: 1;
   background-color: ${theme.palette.N700};
   position: relative;
   overflow: visible;
   display: flex;
   flex-direction: column;
   ${mutualPaddingAndBorderRadiusMixing}
 `}
`
