import styled, { css } from 'styled-components'
import Palette from 'styles/palette'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 216

export enum RESULT {
  SHORT = 'short',
  LONG = 'long',
  NEUTRAL = 'neutral',
  NO_TRADE = 'noTrade',
  OTHER_ISDE = 'otherSide',
  OTHER_ISDE_ON_CHART = 'otherSideOnChart'
}

export const Color = {
  [RESULT.SHORT]: Palette.R400,
  [RESULT.LONG]: Palette.G400,
  [RESULT.NEUTRAL]: '#FFC911',
  [RESULT.NO_TRADE]: Palette.N400,
  [RESULT.OTHER_ISDE]: Palette.N400,
  [RESULT.OTHER_ISDE_ON_CHART]: Palette.N500,
}

type BoxProps = {
  width: string
  height: string
}

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${`${WIDGET_MIN_HEIGHT}px`};
`

export const WidgetBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({theme}) => theme.palette.N700};
  position: relative;
  ${mutualPaddingAndBorderRadiusMixing}
`


export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s4};
  display: flex;
  flex: 1;
`

export const Title = styled.p`
  ${({ theme }) => css`
  display: block;
  ${theme.typography.Body1};
  font-weight: 600;
  color: ${theme.palette.N200};
  padding-bottom: ${theme.spacing.s1};;
  min-height: 28px;
  `}
`

export const StyledChartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const BuySellPercentageSection = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
`


export const InfoSectionWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.typography.SubTitle1}
  `}
`

export const TickerScoreWrapper = styled.div<{ color: typeof Color }>`
  display: flex;
  color: ${({ color }) => color};
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.s6};
  & > :nth-child(n) {
    margin-right: ${({ theme }) => theme.spacing.s2};
  }
  & > :last-child {
    margin-right: 0;
  }
`

export const Ball = styled.div<{ color: typeof Color }>`
  border: ${({ color }) => `4px solid ${color}`};
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-right: ${({ theme }) => theme.spacing.s2};
`

export const WarningStatement = styled.div<{color: string}>`
  margin: ${({ theme }) => `${theme.spacing.s3} 0 ${theme.spacing.s3} 0`};
  color: ${({color}) => color};
  font-weight: 600;
`

export const SourceFromStatement = styled.div`
  color:  ${({ theme }) => theme.palette.N300};
  font-weight: 400;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.s3};
`

export const BrandWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`
