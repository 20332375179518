import styled, { css } from 'styled-components'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 150

export const ItemsWrapper = styled.ul`
  list-style-type: none;
  padding-left: 0;

  > li {
    border-bottom: 1px solid #dddddd;
    &:last-child {
      border-bottom: 0;
    }
  }
`

type BoxProps = {
  width: string
  height: string
}

export const Box = styled.div<BoxProps>`
  ${({ height, width }) => css`
    width: ${width};
    height: ${height};
    min-height: ${`${WIDGET_MIN_HEIGHT}px`};
    display: flex;
    flex-direction: column;
  `}
`

export const WidgetBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${mutualPaddingAndBorderRadiusMixing}
`
