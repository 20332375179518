import styled, { css } from 'styled-components'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 320

type EconomicCalendarWidgetWrapperProps = {
  settedWidth?: number
  settedHeight?: number
  isAdaptive: boolean
}

export const Box = styled.div<EconomicCalendarWidgetWrapperProps>`
 ${({ settedWidth, settedHeight, isAdaptive, }) => css`
   width: ${isAdaptive ? '100%' : `${settedWidth}px`};
   height: ${isAdaptive ? '100%' : `${settedHeight}px`};
   min-height: ${`${WIDGET_MIN_HEIGHT}px`};
   display: flex;
   flex-direction: column;
   position: relative;
  `}
`

export const WidgetBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${({theme}) => theme.palette.N700};
  ${mutualPaddingAndBorderRadiusMixing}
`

export const EconomicCalendarWidgetWrapper = styled.div`
    flex: 1;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: visible;
`
