import styled, { css } from 'styled-components'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 320

type BoxProps = {
  width: string
  height: string
}

export const Box = styled.div<BoxProps>`
${({ width, height }) => css`
    width: ${width};
    height: ${height};
    min-height: ${`${WIDGET_MIN_HEIGHT}px`};
    display: flex;
    flex-direction: column;
 `}
`

export const WidgetBox = styled.div`
  ${({ theme,}) => css`
    width: 100%;
    height: 100%;
    min-height: ${`${WIDGET_MIN_HEIGHT}px`};
    overflow: hidden;
    background-color: ${theme.palette.N700};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    ${mutualPaddingAndBorderRadiusMixing}
  `}
`

export const StyledContainer = styled.div`
 ${({ theme, }) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.s2} ${theme.spacing.s2} ${theme.spacing.s6};
 `}
`

export const StyledHeader = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${theme.spacing.s4};
  `}
`

type StyledTitleProps = {
  isClickable: boolean
}

export const StyledTitle = styled.h4<StyledTitleProps>`
  ${({ theme, isClickable }) => css`
    color: ${theme.palette.N75};
    font-weight: 600;
    ${theme.typography.H4};

    ${isClickable &&
    `
      cursor: pointer;
      &:hover {
        color: ${theme.palette.F400};
      }
    `}
  `}
`

export const StyledChartContainer = styled.div`
  width: 100%;
  height: 96px;
  min-height: 96px;
  position: relative;
  display: flex;
`

// NOTE: Use to measure the height of the remaining space of the flex container
export const CustomizeScrolbarHeightMeasuringWrapper = styled.div`
  flex: 1;
`

export const StyledSymbolInfoRowList = styled.ul`
`

export const StyledSymbolInfoRowListItem = styled.li`
  ${({ theme }) => css`
    padding: ${theme.spacing.s1} 0;
    border-bottom: 1px solid ${theme.palette.N600};
  `}
`

export const StyledRow = styled.div<{ isCurrent: boolean }>`
  ${({ theme, isCurrent }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.s2} ${theme.spacing.s1};
    background-color: ${isCurrent ? theme.palette.F50 : 'transparent'};
    transition: all 0.3s;

    &:hover {
      background-color: ${theme.palette.F50};
    }
  `}
`

export const StyledRowLeftColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing.s1};
  `}
`

export const StyledRowRightColumn = styled.div`
  margin-left: auto;
  text-align: right;
`

export const StyledSymbolDisplayName = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: ${theme.spacing.s2};
  `}
`

export const StyledSymbolDisplayNameTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.N200};
    font-weight: 600;
    ${theme.typography.Body2};
    transition: all 0.3s;
  `}
`

export const StyledSymbolDisplayNameDescription = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.N450};
    ${theme.typography.H6};
  `}
`

type StyledSymbolRateProps = {
  isIncreasing: boolean
}

export const StyledSymbolRate = styled.div<StyledSymbolRateProps>`
  ${({ theme, isIncreasing }) => css`
    color: ${isIncreasing ? theme.palette.G400 : theme.palette.R400};
    ${theme.typography.H6};
  `}
`

export const StyledTabListWrapper = styled.div`
`

export const StyledTabList = styled.ul`
 overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  ${({ theme }) => css`
    display: flex;
    margin: 0 ${`-${theme.spacing.s1}`};
  `}
`

type StyledTabListItemProps = {
  isActive: boolean
}

export const StyledTabListItem = styled.li<StyledTabListItemProps>`
  ${({ theme, isActive }) => css`
    color: ${isActive ? theme.palette.F400 : theme.palette.N200};
    background-color: ${isActive ? theme.palette.F50 : 'transparent'};
    padding: ${theme.spacing.s1} ${theme.spacing.s2};
    margin: 0 ${theme.spacing.s1};
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.3s;
    ${theme.typography.H6}
  `}
`

export const StyledCategoriesContainer = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.s3};
  `}
`

export const StyledRangesContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.s3} 0 ${theme.spacing.s4};
  `}
`

export const StyledLoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`
