import { SymbolPair } from '../types'

export const defaultSymbolPairs: SymbolPair[] = [
  {
    id: '1',
    symbolName: 'AUD/CAD',
  },
  {
    id: '2',
    symbolName: 'USD/JPY',
  },
  {
    id: '3',
    symbolName: 'GBP/AUD',
  },
  {
    id: '4',
    symbolName: 'XAU/USD',
  },
  {
    id: '5',
    symbolName: 'WTI',
  },
  {
    id: '6',
    symbolName: 'SP500',
  },
]

export const defaultProps = {
  width: 950,
  height: 56,
  isAdaptive: false,
  symbolPairs: defaultSymbolPairs,
  acyUrl: '',
  showBrand: true,
  isUseDefaultHeight: true
  // isShowBeneathLink: false,
}
