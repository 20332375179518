// @from  https://stackoverflow.com/a/51123745/5599652

export function makeFunctionSerializeAndDeserializeAble() {
  Function.deserialise = function (key, data) {
    return data instanceof Array && data[0] == 'window.Function'
      ? new (Function.bind.apply(
        Function,
        [Function].concat(data[1], [data[2]]),
      ))()
      : data
  }
  Function.prototype.toJSON = function () {
    const whitespace = /\s/
    const pair = /\(\)|\[\]|\{\}/

    const args = new Array()
    let string = this.toString()

    const fat = new RegExp(
      '^s*(' + (this.name ? this.name + '|' : '') + 'function' + ')[^)]*\\(',
    ).test(string)

    let state = 'start'
    const depth = new Array()
    let tmp

    for (let index = 0; index < string.length; ++index) {
      const ch = string[index]

      switch (state) {
        case 'start':
          if (whitespace.test(ch) || (fat && ch != '(')) continue

          if (ch == '(') {
            state = 'arg'
            tmp = index + 1
          } else {
            state = 'singleArg'
            tmp = index
          }
          break

        case 'arg':
        case 'singleArg':
          const escaped = depth.length > 0 && depth[depth.length - 1] == '\\'
          if (escaped) {
            depth.pop()
            continue
          }
          if (whitespace.test(ch)) continue

          switch (ch) {
            case '\\':
              depth.push(ch)
              break

            case ']':
            case '}':
            case ')':
              if (depth.length > 0) {
                if (pair.test(depth[depth.length - 1] + ch)) depth.pop()
                continue
              }
              if (state == 'singleArg') throw ''
              args.push(string.substring(tmp, index).trim())
              state = fat ? 'body' : 'arrow'
              break

            case ',':
              if (depth.length > 0) continue
              if (state == 'singleArg') throw ''
              args.push(string.substring(tmp, index).trim())
              tmp = index + 1
              break

            case '>':
              if (depth.length > 0) continue
              if (string[index - 1] != '=') continue
              if (state == 'arg') throw ''
              args.push(string.substring(tmp, index - 1).trim())
              state = 'body'
              break

            case '{':
            case '[':
            case '(':
              if (
                depth.length < 1 ||
                !(
                  depth[depth.length - 1] == '"' ||
                  depth[depth.length - 1] == "'"
                )
              )
                depth.push(ch)
              break

            case '"':
              if (depth.length < 1) depth.push(ch)
              else if (depth[depth.length - 1] == '"') depth.pop()
              break
            case "'":
              if (depth.length < 1) depth.push(ch)
              else if (depth[depth.length - 1] == "'") depth.pop()
              break
          }
          break

        case 'arrow':
          if (whitespace.test(ch)) continue
          if (ch != '=') throw ''
          if (string[++index] != '>') throw ''
          state = 'body'
          break

        case 'body':
          if (whitespace.test(ch)) continue
          string = string.substring(index)

          if (ch == '{') string = string.replace(/^{\s*(.*)\s*}\s*$/, '$1')
          else string = 'return ' + string.trim()

          index = string.length
          break

        default:
          throw ''
      }
    }

    return ['window.Function', args, string]
  }
}

export const isInBrowser = () => {
  if (typeof window !== 'undefined') {
    return true
  }
  return false
}

export const getURLWithoutQueryParameter = () => {
  if (isInBrowser()) {
    return window.location.origin + window.location.pathname
  }
  return ''
}

const _3rdPartyCookieBlockedErrorMsg = '3rd party cookie is blocked, this also cause can not access localStorage/sessionStorage'
let printed = false

const printErrorLog = () => {
  printed && console.info(_3rdPartyCookieBlockedErrorMsg)
  printed = true
}

// NOTE: In order to tame this usecase: https://stackoverflow.com/a/69004255/5599652
export const SafeStorage = {
  localStorage: {
    setItem: (key: string, content: string): boolean => {
      try {
        if (!isInBrowser()) {
          return false
        }

        /*eslint-disable */
        window.localStorage.setItem(key, content)
        return true
      } catch (err) {
        printErrorLog()
        return false
      }
    },
    getItem: (key: string): string | null => {
      try {
        if (!isInBrowser()) {
          return null
        }

        /*eslint-disable */
        return window.localStorage.getItem(key)
      } catch (err) {
        printErrorLog()
        return null
      }
    },
    removeItem: (key: string) => {
      try {
        if (!isInBrowser()) {
          return null
        }

        /*eslint-disable */
        window.localStorage.removeItem(key)
        return true
      } catch (err) {
        printErrorLog()
        return false
      }
    },
  },
  sessionStorage: {
    setItem: (key: string, content: string): boolean => {
      try {
        if (!isInBrowser()) {
          return false
        }

        /*eslint-disable */
        window.sessionStorage.setItem(key, content)
        return true
      } catch (err) {
        printErrorLog()
        return false
      }
    },
    getItem: (key: string): string | null => {
      try {
        if (!isInBrowser()) {
          return null
        }

        /*eslint-disable */
        return window.sessionStorage.getItem(key)
      } catch (err) {
        printErrorLog()
        return null
      }
    },
    removeItem: (key: string) => {
      try {
        if (!isInBrowser()) {
          return null
        }

        /*eslint-disable */
        window.sessionStorage.removeItem(key)
        return true
      } catch (err) {
        printErrorLog()
        return false
      }
    }
  }
}
