import styled from 'styled-components'
import { mutualPaddingAndBorderRadiusMixing } from 'styles/mutualMixing'

export const WIDGET_MIN_HEIGHT = 260

type StyledContainerProps = {
  width: string
  height: string
}


export const Box = styled.div<StyledContainerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${`${WIDGET_MIN_HEIGHT}px`};
  display: flex;
  flex-direction: column;
`

export const WidgetBox = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${mutualPaddingAndBorderRadiusMixing}

`
